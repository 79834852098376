import React from "react";
import { graphql } from 'gatsby';
import { locale } from '../locale';
import Layout from "../components/Layout"
import SEO from "../components/seo";
import Content from '../views/Blog/Article';

export default ({ data, location }) => {
  const { blogpostsJson } = data;
  return (
    <Layout overlay page='blog'
      url='_url:blog'>
      <SEO title={blogpostsJson.metadata.title}
        lang={
          locale === 'da' ? 'da-DK'
          : 'en'
        }
        description={blogpostsJson.metadata.description}
        meta={[{
          name: 'keywords',
          content: blogpostsJson.metadata.keyword
        }]}
        location={location}/>
      <Content id={blogpostsJson._id}
        post={blogpostsJson}/>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    blogpostsJson(fields: { slug: { eq: $slug } }) {
      metadata {
        description
        keyword
        title
        createdAt
        url
      }
      title
      content
      indexable
      createdAt
      banner {
        url
      }
      author {
        photo {
          url
        }
        name
        title
      }
      _id
    }
  }
`