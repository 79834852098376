import React from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '../../../../../components/Typography';
import styles from './styles.module.sass';

export default ({ content }) => (
  <>
    <Typography variant='subtitle2' weight='light' color='main' align='left'
      className={styles.content}>
      <ReactMarkdown source={content}
        renderers={{
          link: ({ children, href }) => (
            <a className={styles.hyperlink} href={href}>
              {children}
            </a>
          ),
          paragraph: ({ children }) => (
            <p className={styles.paragraph}>
              {children}
            </p>
          ),
          listItem: ({ children }) => (
            <li className={styles.paragraph}>
              {children}
            </li>
          ),
          image: ({ alt, src }) => (
            <img alt={alt}
              src={src}
              className={styles.imageContainer}/>
          ),
          heading: ({ level, children }) => (
            <Typography variant={`h${level}`}
              color='main'
              align='left'
              className={styles[`heading${level}`]}>
              {children}
            </Typography>
          ),
          blockquote: ({ children }) => (
            <blockquote className={styles.quote}>
              <em>
                {children}
              </em>
            </blockquote>
          )
        }}/>
    </Typography>
  </>
)