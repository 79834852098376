import React from 'react';
import 'whatwg-fetch';
import { Grid } from '@material-ui/core';
import CallToAction from '../../CallToAction';
import Banner from './Banner';
import Author from './Author';
import Content from './Content';
import NotFound from './NotFound';
import styles from './styles.module.sass';


export default ({ post }) => {
  if (post) {
    const { banner, author, ...rest } = post;
    return (
      <div>
        <Banner banner={banner}/>
        <Grid container className={styles.contentContainer}>
          <Author author={author}/>
          <Content {...rest}/>
          <Grid item xs={12} sm={12} md={12} lg={2} xl={2}/>
        </Grid>
        <CallToAction/>
      </div>
    );
  }
  return <NotFound/>;
}
