import React from 'react';
import { Grid } from '@material-ui/core';
import { locale } from '../../../../locale';
import Typography from '../../../../components/Typography';
import Body from './Body';
import styles from './styles.module.sass';

export default ({ content, title, createdAt }) => (
  <Grid item xs={12} sm={12} md={12} lg={8} xl={8}
    className={styles.container}>
    <Typography align='center' weight='regular' color='highlight' variant='h5' className={styles.postedAt}>
      {new Date(createdAt).toLocaleString(locale, { dateStyle: 'long'})}
    </Typography>
    <Typography align='center' variant='h1' weight='bold' className={styles.title}>
      {title}
    </Typography>
    <div className={styles.bodyContainer}>
      <Body content={content}/>
    </div>
  </Grid>
)