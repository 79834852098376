import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Img from 'gatsby-image/withIEPolyfill';
import { strapiUrl } from '../../../../templates/constants';
import styles from './styles.module.sass';

export default ({ banner }) => (
  <StaticQuery
    query={graphql`
      query {
        def: imageSharp (fluid: {
          originalName: { eq: "About-Us-Hero.png" }
        }) {
          fluid(maxWidth: 1600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ def }) => (
      <div className={clsx(
          styles.container,
          !banner ? styles.defaultContainer : ''
        )}>
        {banner
        ? <img src={strapiUrl + banner.url}
            alt='Artikel banner'
            className={styles.image}/>
        : <Img imgStyle={{ height: '100%'}}
            className={styles.imageContainer}
            objectFit='cover'
            fluid={def.fluid}/>}
      </div>
    )}
  />
)