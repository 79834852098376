import React from "react";
import Title from '../../../../components/Title';
import Subtitle from '../../../../components/Subtitle';

export default () => (
  <>
    <Title variant='h1'>
      IKKE FUNDERET
    </Title>
    <Subtitle>
      Denne side findes ikke
    </Subtitle>
  </>
);